<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu @update-currency="updateCurrency" :currency-codes="currencyCodes" v-if="menuVisible"/>
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{'active': menuVisible}">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Header @update-currency="updateCurrency" :currency-codes="currencyCodes"/>
        <router-view @currency="currency" @postNickname="postNickname" :currency="currency" @login="login" @forgotPass="forgotPass"
                     @registration="registration" @sendContactForm="sendContactForm" :isAuth="isAuth"
                     :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @chooseCategoty="chooseCategoty"
                     :activeCategory="activeCategory" @orderSubmit="orderSubmit"/>
        <Footer :settings="settings" @getTextPageContent="getTextPageContent"/>
        <div class="modal modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="title">Cookies In Use</div>
            <div class="desc chekbox-label">
              Welcome to Evolmine! We employ cookies and similar technologies to enhance the functionality of our
              website, ensuring a seamless and personalized browsing experience for you. Some cookies, crucial for basic
              functionality, are set automatically without requiring explicit consent. Alongside essential cookies, we
              may introduce optional cookies and similar technologies that provide various benefits, enriching your
              interaction with our website. Since these are non-essential, their usage depends on your preferences. For
              more detailed information, please refer to our <a class="title" @click="goToPage('cookie')">Cookie
              Policy</a>.
            </div>
            <button class="button blue" @click="acceptCookies">Accept</button>
            <button class="button blue" @click="declineCookies">Reject</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import {scroller} from 'vue-scrollto/src/scrollTo'
import {loadScriptsFromString} from "@/utils/load_scripts";


export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Menu
  },
  data: function () {
    return {
      cookesModalIsVisible: false,
      settings: {},
      nav: [
        {
          title: 'STORE',
          path: '/store/'
        },
        {
          title: 'FAQ',
          path: '/faq'
        },
        {
          title: 'CONTACTS',
          path: '/contacts'
        }
      ],
      pageLoaded: false,
      menuVisible: false,
      isAuth: false,
      error: '',
      successMessage: '',
      textPageList: [],
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      orderHistory: '',
      imgDomain: '',
      addToCartChosenItem: '',
      currency: '',
      cartContents: [],
      totalSum: '0.00',
      categories: [],
      activeCategory: '',
      socialLinks: [],
      faq: [],
      newOffer: [],
      currencies: []
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
      this.clearError();
    },
    currency: function () {
      this.getCartContents()
      this.getOffer();
    }
  },
  methods: {
    updateCurrency(val) {
      this.currency = val;
    },
    getOffer() {
      return this.$http.get(process.env.VUE_APP_API + 'products?type=new')
          .then((res) => {
            this.newOffer = res.data.payload;
          })
          .catch(() => {
          })
    },
    getFaq() {
      return this.$http.get(process.env.VUE_APP_API + 'faq')
          .then((res) => {
            this.faq = res.data.payload.map(({question, answer}) => ({
              title: question,
              content: answer
            }));
          })
          .catch(() => {
          })
    },
    wrapFirstLetterInSpan(text) {
      if (typeof text !== 'string' || text.length === 0) {
        return text;
      }
      const firstLetter = text.charAt(0);
      const restOfString = text.slice(1);

      return `<span>${firstLetter}</span>${restOfString}`;
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
          .then((res) => {
            this.textPageList = res.data.payload
          })
          .catch(() => {

          })
    },
    goToPage(item) {
      let newItem;
      if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i].id;
          }
        }
      } else {
        newItem = item
      }
      this.$router.push({path: '/pages/' + newItem, params: {data: newItem}})
      this.getTextPageContent(newItem)
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    postNickname(nickname) {
      let data = {
        "nickname": nickname
      }
      this.$http.post(process.env.VUE_APP_API + 'set-nickname', data)
          .then((res) => {
            console.log(res)
            if (res.status == '200') {
              this.$router.push({path: '/categories'})
            }
          })
          .catch((res) => {
            console.log(res)
            this.error = res.response.data.message
          })
    },
    getSettings() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'settings')
          .then((res) => {
            self.settings = res.data.payload;
            console.log(self.settings)
          })
          .catch(() => {
          });
    },
    getGoogleTagManagerSnippet() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
          .then((res) => {
            const snippet = res.data.payload.google_tag;
            if (snippet) {
              loadScriptsFromString(snippet);
            }
          })
          .catch((error) => {
            console.error("Error fetching Google Tag Manager snippet:", error);
          });
    },
    clearError() {
      this.error = '';
    },
    login(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'login', data)
          .then((res) => {
            if (res.data.status == "OK") {
              self.isAuth = true;
              this.clearError();
              this.getOrderHistory();
              this.getCartContents();
              this.$router.push({path: '/'})
            } else {
              this.error = res.data.message
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
          })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
          .then((res) => {
            if (res.data.status == "OK") {
              this.successMessage = 'Success';
              this.error = ''
              setTimeout(() => {
                self.successMessage = '';
              }, 2000)
            } else {
              this.error = res.response.data.message
            }
          })
          .catch((error) => {
            this.error = error.response.data.message
          })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
            .then((res) => {
              if (res.data.status == "OK") {
                this.clearError();
                self.isAuth = true;
                self.getCartContents();
                this.$router.push({path: '/'})
              } else {
                this.error = res.data.message
              }
            })
            .catch((res) => {
              this.error = res.response.data.message;
            })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
          .then(() => {
            if (this.$route.name != 'Home') {
              this.$router.push({path: '/'})
            }
            this.isAuth = false;
            this.$router.push({path: '/'});
          })
          .catch((res) => {
            if (res.response.status === 401) {
              console.log(res);
            }
          })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
          .then((res) => {
            this.orderHistory = res.data.payload
          })
          .catch(() => {

          })
    },
    formatDate(num) {
      let objectDate = new Date(num);
      let day = objectDate.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }

      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    goToPrivacy() {
      let pageId = '';
      console.log(this.textPageList)
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        }
      }
    },
    goToTerms() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        }
      }
    },

    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
          .then((res) => {
            this.textPageTitle = res.data.payload.title;
            this.textPageContent = res.data.payload.content;
            this.textPageHeader = res.data.payload.header;
          })
          .catch(() => {

          })
    },
    getSocialLinks() {
      return this.$http.get(process.env.VUE_APP_API + 'social-links')
          .then((res) => {
            this.socialLinks = res.data.payload;
          })
          .catch(() => {
          });
    },
    openSignIn() {
      this.error = '';
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    },
    openRecover() {
      this.$router.push({path: '/recover'});
    },
    sendContactForm(data) {
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
          .then((res) => {
            if (res.data.status == "OK") {
              let self = this;
              this.successMessage = 'Thank you your message has been sent';
              this.error = ''
              setTimeout(() => {
                self.successMessage = '';
              }, 2000)
            } else {
              this.error = res.data.message
            }
          })
          .catch((res) => {
            this.error = res.response.data.message
          })
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id,
        'count': '1'
      }
      if (item.count > 1) {
        cartItem = {
          'id': id,
          'count': item.count - 1
        }
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
          .then((res) => {
            self.cartContents = res.data.cart;
            this.getCartContents();
          })
          .catch(() => {

          })

    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/remove', cartItem)
          .then((res) => {
            self.cartContents = res.data.cart;
            this.getCartContents();
          })
          .catch(() => {

          })
    },
    addToCart(item, id) {
      if (!this.isAuth) {
        this.openSignIn();
      } else {
        let self = this;

        let cartItem = {
          'id': id,
          'count': 1
        }

        for (var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == id) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              'id': id,
              'count': cartItemCount + 1
            }
            break;
          }
        }

        this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
            .then((res) => {
              this.getCartContents();
              self.addToCartChosenItem = cartItem.id;
              self.cartContents = res.data.cart
              setTimeout(() => {
                self.addToCartChosenItem = '';
              }, 2000)
            })
            .catch((res) => {
              if (res.response.status === 401) {
                this.openSignIn();
              }
            })
      }
    },
    emptyCart() {
      this.$http.post(process.env.VUE_APP_API + 'cart/clear')
          .then((res) => {
            if (res.data.status == "OK") {
              this.getCartContents();
            }
          })
          .catch(() => {

          })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
          .then((res) => {

            console.log(res.data.cart)
            this.cartContents = res.data.cart;

            let total = 0;
            for (let i in this.cartContents) {
              let newPrice;
              if (this.cartContents[i].count > 1) {
                newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
              } else {
                newPrice = parseFloat(this.cartContents[i].item.price);
              }
              total += newPrice;
            }
            this.totalSum = total.toFixed(2);
          })
          .catch(() => {

          })
    },
    scrollToSocial() {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      setTimeout(() => {
        const firstScrollTo = scroller()
        firstScrollTo('#social')
      }, 200)
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    chooseCategoty(item) {
      this.activeCategory = item;
      console.log('Choose category');
      this.$router.push({path: '/store' + item.url})
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
          .then((res) => {
            this.categories = res.data.payload;
            const category = this.categories.find(({url}) => url.replace(/\//g, '') == this.$route.params.url);

            if(category) {
              this.activeCategory = category

              console.log(this.$route);

              if (this.$route.name === 'Categories') {
                console.log('Redirect to store 1');
                this.$router.push('/store' + this.activeCategory.url)
              }

              return;
            }

            for (const obj of this.categories) {
              if (obj.url.replace(/\//g, '') == this.$route.params.url) {
                this.activeCategory = obj;
                break;
              } else {
                this.activeCategory = this.categories[0];
                const storeIndex = this.nav.findIndex(item => item.title === 'STORE');

                if (storeIndex !== -1) {
                  const storeNavItem = this.nav[storeIndex];

                  this.$set(this.nav, storeIndex, {
                    ...storeNavItem,
                    path: '/store' + this.activeCategory.url
                  });

                  if (this.$route.name === 'Categories') {
                    console.log('Redirect to store');
                    this.$router.push('/store' + this.activeCategory.url)
                  }
                }
              }
            }
          })
          .catch(() => {

          })
    },
    orderSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
          .then((res) => {
            window.location = res.data.redirect_url;
          })
          .catch((res) => {
            this.error = res.response.data.message;
          })
    },
    fetchCurrencies() {
      this.$http.get(process.env.VUE_APP_API + 'currencies')
          .then((res) => {
            this.currencies = res.data.payload;

            if (this.currencies.length === 1)  {
              this.currency = this.currencies[0].code;
            } else if (this.currency) {
              if (this.currencies.length && !this.currencies.find(({code}) => code === this.currency)) {
                this.currency = this.currencies[0].code;
              }
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
          })
    }
  },
  mounted: function () {
    this.getTextPages();
    this.getSettings();
    this.getCategories();
    this.getSocialLinks();
    this.cookiesModal();
    this.getFaq();
    this.getOffer();
    this.currency = localStorage.getItem('currency') || process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;

    self.getGoogleTagManagerSnippet();
    self.fetchCurrencies();
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
        .then((res) => {
          console.log('auth: ' + res.data.authenticated);
          self.isAuth = res.data.authenticated

          if (res.data.authenticated) {
            self.getOrderHistory();
            self.getCartContents();
          }
        })
        .catch(() => {
        })

  },
  computed: {
    currencyCodes() {
      return this.currencies.map(({code}) => code);
    }
  }
}
</script>
